<template>
  <div class="security-center">
    <NavBar :title="$t('ui_security_center')" theme="blue" left="menu" right="Home"></NavBar>
    <Cell
      v-for="(item, index) in list"
      :title="upperCase($t(item.text))"
      :value="$t(item.value || '')"
      is-link
      :label="upperCase($t(item.description, [3, cardNumber]))"
      :icon="require(`@/assets/wap/icon-personal-${item.icon}.png`)"
      @click="termClick(item.name)"
      :key="index"
    />
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {NavBar} from '@/common/wap';
import {Cell} from 'vant';
import {App, Format} from '@/services';
import {GetUser} from '@/model/tokenGetUser';
import {GetCards} from '@/model/bank';

@Options({
  components: {
    NavBar,
    Cell
  },
  computed: {
    list() {
      return [
        {
          text: 'personal_information',
          name: 'PersonalInformation',
          description: 'ui_wap_text_073',
          icon: 'data'
        },
        {
          text: 'ui_wap_text_071', // 手機驗證
          name: 'PhoneVerification',
          description: 'ui_wap_text_074',
          icon: 'phone',
          value: this.userinfo.phoneNumber.verification ? 'ui_verified' : 'ui_unverified'
        },
        {
          text: 'ui_wap_text_072', // 信箱驗證
          name: 'MailVerification',
          description: 'ui_wap_text_075',
          icon: 'mail',
          value: this.userinfo.email.verification ? 'ui_verified' : 'ui_unverified'
        },
        {
          text: 'ui_bank_card_management', // 银行卡管理
          name: 'BankCard',
          description: 'ui_wap_text_076',
          icon: 'bankcard'
        },
        {
          text: 'btn_login_password',
          name: 'Password',
          description: 'ui_wap_text_077',
          icon: 'password'
        }
      ];
    }
  }
})
export default class SecurityCenter extends Vue {
  upperCase = Format.upperCase;
  userinfo = App.getUserinfo();
  cardNumber = 0;
  async mounted() {
    await GetUser.action();
    const cardsInfo = await GetCards.read();
    if (!(cardsInfo instanceof Error)) {
      this.cardNumber = cardsInfo.cards;
    }
    this.userinfo = App.getUserinfo();
  }
  /**
   * router handler
   * name to route name
   */
  termClick(name: string) {
    this.$router.push({name});
  }
}
</script>
<style lang="scss" scoped>
.security-center {
  background-color: #f6f7f8;
  height: 100%;
  .nav-right {
    font-size: 0.56rem;
    color: #333333;
    line-height: 0.56rem;
    margin-right: 0.48rem;
    @extend .clickAction;
  }
}
:deep(.van-cell__title) {
  flex: 2;
}
:deep(.van-cell__value) {
  color: $verified;
}
:deep(.van-cell__label) {
  word-break: keep-all;
  white-space: normal;
}
:deep(.van-icon__image) {
  width: 1rem;
  height: 1rem;
}
[lang*='en'] :deep(.van-cell__label) {
  white-space: normal !important;
}
</style>

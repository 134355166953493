
import {Options, Vue} from 'vue-class-component';
import {NavBar} from '@/common/wap';
import {Cell} from 'vant';
import {App, Format} from '@/services';
import {GetUser} from '@/model/tokenGetUser';
import {GetCards} from '@/model/bank';

@Options({
  components: {
    NavBar,
    Cell
  },
  computed: {
    list() {
      return [
        {
          text: 'personal_information',
          name: 'PersonalInformation',
          description: 'ui_wap_text_073',
          icon: 'data'
        },
        {
          text: 'ui_wap_text_071', // 手機驗證
          name: 'PhoneVerification',
          description: 'ui_wap_text_074',
          icon: 'phone',
          value: this.userinfo.phoneNumber.verification ? 'ui_verified' : 'ui_unverified'
        },
        {
          text: 'ui_wap_text_072', // 信箱驗證
          name: 'MailVerification',
          description: 'ui_wap_text_075',
          icon: 'mail',
          value: this.userinfo.email.verification ? 'ui_verified' : 'ui_unverified'
        },
        {
          text: 'ui_bank_card_management', // 银行卡管理
          name: 'BankCard',
          description: 'ui_wap_text_076',
          icon: 'bankcard'
        },
        {
          text: 'btn_login_password',
          name: 'Password',
          description: 'ui_wap_text_077',
          icon: 'password'
        }
      ];
    }
  }
})
export default class SecurityCenter extends Vue {
  upperCase = Format.upperCase;
  userinfo = App.getUserinfo();
  cardNumber = 0;
  async mounted() {
    await GetUser.action();
    const cardsInfo = await GetCards.read();
    if (!(cardsInfo instanceof Error)) {
      this.cardNumber = cardsInfo.cards;
    }
    this.userinfo = App.getUserinfo();
  }
  /**
   * router handler
   * name to route name
   */
  termClick(name: string) {
    this.$router.push({name});
  }
}
